import {sessionStorage} from "../libs/web-storage";
import {isValidToken} from "./token";

const storedTokens = JSON.parse(sessionStorage.getItem("galenTokens"));

const [vcoTokenEntry] = (storedTokens || []).filter(
    token => token.key === "vco"
);
const token = vcoTokenEntry ? vcoTokenEntry.token : null;
if (!isValidToken(token)) {
    sessionStorage.clear();
    window.location.href = "/login";
}
