/**
 * @module
 * @name TokenManager
 * @description Methods for managing JSON Web Token
 * @returns Singleton instance of JWTManager
 */

import events from "events";
import moment from "moment";
import {galenLogger} from "../libs/galen-logger";
import {UnsafePojo} from "../libs/galen/objects/base-types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TokenEventCallback = (...args: any[]) => void;

export enum TokenEventConstants {
    TOKEN_WILL_EXPIRE = "TOKEN_WILL_EXPIRE",
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    TOKEN_CREATED = "TOKEN_CREATED",
    TOKEN_DESTROYED = "TOKEN_DESTROYED",
    TOKENS_CLEARED = "TOKENS_CLEARED",
}

type TokenClaims = {
    nbf: number;
    exp: number;
    [index: string]: unknown;
};

type TokenData = {
    header: UnsafePojo;
    claims: TokenClaims;
    crypto: string;
};

export function parseTokenData(token: string): TokenData {
    const parts = token.split(".");
    if (parts.length !== 3) {
        // Sanity Check
        throw new Error("Tokens must have 3 parts");
    }
    return {
        header: JSON.parse(atob(parts[0])),
        claims: JSON.parse(atob(parts[1])),
        crypto: parts[2],
    };
}

/**
 * @name isValidToken
 * @param {string} token - Base64 encoded JWT string
 * @description validates token for expiration and nbf dates
 * @returns {boolean} - True if valid.
 */
export function isValidToken(token: string) {
    let tokenData: TokenData | null = null;
    try {
        tokenData = parseTokenData(token);
    } catch (err) {
        return false;
    }
    // Is the not-before-now date (nbf) before now?
    const isAfterNotBefore =
        !tokenData.claims.nbf ||
        (!!tokenData.claims.nbf &&
            moment.unix(tokenData.claims.nbf).diff(moment(), "seconds") <= 0);
    // Is the expiration date after now?
    const isBeforeExpiration =
        !tokenData.claims.exp ||
        (!!tokenData.claims.exp &&
            moment.unix(tokenData.claims.exp).diff(moment(), "seconds") > 0);
    if (!isAfterNotBefore) {
        galenLogger.logError(
            `Received token that is not yet valid ${moment.unix(tokenData.claims.nbf).format("YYY-MM-DD hh:mm")} is before ${moment().format("YYY-MM-DD hh:mm")}`
        );
    }
    // return isAfterNotBefore && isBeforeExpiration; // Having timing issues with this test. (JN)
    return isBeforeExpiration;
}

export class Token extends events.EventEmitter {
    constructor(
        serializedToken: string,
        addTimeoutWatch = true,
        expirationWarningLength = 300
    ) {
        super();
        this.rawToken = serializedToken;
        this.tokenData = parseTokenData(serializedToken);

        if (addTimeoutWatch) {
            const expirationTimeout = moment
                .unix(this.tokenData.claims.exp)
                .diff(moment());
            this._timeoutExpired = setTimeout(() => {
                this.emit(TokenEventConstants.TOKEN_EXPIRED, {
                    action: TokenEventConstants.TOKEN_EXPIRED,
                    expiration: moment.unix(this.tokenData.claims.exp),
                });
            }, expirationTimeout);

            const calculatedTimeout = expirationWarningLength * 1000; // (moment.unix(this.tokenData.claims.exp).diff(moment(), "seconds") - this.timeoutWindow) * 1000;
            const expirationTime =
                moment
                    .unix(this.tokenData.claims.exp)
                    .diff(moment(), "seconds") * 1000;
            const timeout =
                expirationTime > calculatedTimeout ? calculatedTimeout : 0;
            this._timeoutWillExpire = setTimeout(() => {
                this.emit(TokenEventConstants.TOKEN_WILL_EXPIRE, {
                    action: TokenEventConstants.TOKEN_WILL_EXPIRE,
                    expiration: moment.unix(this.tokenData.claims.exp),
                });
            }, timeout);
        }
    }

    tokenData: TokenData;
    readonly rawToken: string;
    private readonly _timeoutExpired: ReturnType<typeof setTimeout>;
    private readonly _timeoutWillExpire: ReturnType<typeof setTimeout>;

    addEventListener = (callback: TokenEventCallback) => {
        this.on(TokenEventConstants.TOKEN_WILL_EXPIRE, callback);
        this.on(TokenEventConstants.TOKEN_EXPIRED, callback);
        this.on(TokenEventConstants.TOKEN_CREATED, callback);
        this.on(TokenEventConstants.TOKEN_DESTROYED, callback);
        this.on(TokenEventConstants.TOKENS_CLEARED, callback);
    };

    removeEventListener = (callback: TokenEventCallback) => {
        this.removeListener(TokenEventConstants.TOKEN_WILL_EXPIRE, callback);
        this.removeListener(TokenEventConstants.TOKEN_EXPIRED, callback);
        this.removeListener(TokenEventConstants.TOKEN_CREATED, callback);
        this.removeListener(TokenEventConstants.TOKEN_DESTROYED, callback);
        this.removeListener(TokenEventConstants.TOKENS_CLEARED, callback);
    };

    destroy() {
        if (!!this._timeoutWillExpire) {
            clearTimeout(this._timeoutWillExpire);
        }
        if (!!this._timeoutExpired) {
            clearTimeout(this._timeoutExpired);
        }
    }
}
