import {galenLogger} from "./galen-logger";

export enum StorageType {
    LOCAL_STORAGE = "localStorage",
    SESSION_STORAGE = "sessionStorage",
}

const isStorageTypeAvailable = (
    type: StorageType
): [true] | [false, string | Error] => {
    let storage = null;
    let length = 0;
    try {
        const x = "__storage_test__";
        storage = window[type];
        length = storage.length;
        storage.setItem(x, x);
        storage.removeItem(x);
        return [true];
    } catch (e) {
        const errorIsExceededQuota =
            e instanceof DOMException &&
            (e.code === 22 ||
                e.name === "QuotaExceededError" || // everything except Firefox
                e.code === 1014 ||
                e.name === "NS_ERROR_DOM_QUOTA_REACHED"); // Firefox
        const storageHasData = !!storage && length !== 0;

        return errorIsExceededQuota && storageHasData ? [true] : [false, e]; // any error except QuotaExceededError means the storage is unusable.
    }
};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createLocalStorageFallback = (
    sessStorage: Storage | null
): Readonly<Storage> | null => {
    if (!sessStorage) {
        return null;
    }
    const keyPrefix = "__localStorage_fallback__";
    const keyListKey = "__localStorage_fallback_keys__";

    // keep the list of keys in sessionStorage as well, so it survives a refresh.
    sessStorage.setItem(keyListKey, "");

    const localStorageFallback = {
        getItem: (key: NonNullable<string>) =>
            sessStorage.getItem(keyPrefix + key),

        key: (index: NonNullable<number>) => {
            const keys = sessStorage.getItem(keyListKey)?.split(", ") ?? [];
            return keys[index] || null;
        },

        setItem: (key: NonNullable<string>, val: string | null) => {
            // validate key
            if (key.includes(", ")) {
                throw new Error(
                    `localStorage fallback keys may not contain the substring ", ". You passed "${key}".`
                );
            }

            // add key if necessary
            const keys = sessStorage.getItem(keyListKey);
            if (!keys?.includes(keyPrefix + key)) {
                // key is new, add it to the keyList
                const newKeys = !!keys ? `${keys}, ${key}` : key;
                sessStorage.setItem(keyListKey, newKeys);
            }

            // set item
            return sessStorage.setItem(keyPrefix + key, val ?? "");
        },

        get length() {
            const keys = sessStorage.getItem(keyListKey);
            return keys?.split(", ").length ?? 0;
        },

        removeItem: (key: NonNullable<string>) => {
            // remove key
            const keys = sessStorage.getItem(keyListKey);
            const newKeys = keys?.replace(new RegExp(`/(, )?${key}/g`), "");
            sessStorage.setItem(keyListKey, newKeys ?? "");

            // remove item
            return sessStorage.removeItem(keyPrefix + key);
        },

        clear: () => {
            // remove all items
            const keysArray = sessStorage.getItem(keyListKey)?.split(", ");
            keysArray?.forEach(k => sessStorage.removeItem(keyPrefix + k));

            // clear keyList
            sessStorage.setItem(keyListKey, "");
        },
    };

    return localStorageFallback;
};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const [sessStrgIsAvailable, sessStrgErr] = isStorageTypeAvailable(
    StorageType.SESSION_STORAGE
);

if (!sessStrgIsAvailable) {
    // no session storage, log error and alert user
    galenLogger.logError("sessionStorage is unsupported or unavailable.");
    galenLogger.logError(sessStrgErr);

    // eslint-disable-next-line no-alert
    window.alert(
        "An error occurred: sessionStorage is unsupported or unavailable.\nPlease contact your administrator."
    );
}

export const sessionStorage: Readonly<Storage> | null = sessStrgIsAvailable
    ? window.sessionStorage
    : null;

const [lclStrgIsAvailable, lclStrgErr] = isStorageTypeAvailable(
    StorageType.LOCAL_STORAGE
);

if (!lclStrgIsAvailable) {
    // no local storage, fallback to session storage and log error
    galenLogger.logError(
        "localStorage is unavailable, falling back to sessionStorage."
    );
    galenLogger.logError(lclStrgErr);
}

export const localStorage: Readonly<Storage> | null = lclStrgIsAvailable
    ? window.localStorage
    : createLocalStorageFallback(sessionStorage);
